import { OnDataOptions, useSubscription } from '@apollo/client';
import { CLIENT_DELETED } from './useClientDeletedSubscription.gql';
import {
  ClientDeletedSubscriptionSubscription,
  ClientDeletedSubscriptionSubscriptionVariables,
} from './__generated__/useClientDeletedSubscription.gql';

export const useClientDeletedSubscription = (
  onData: (data: OnDataOptions<ClientDeletedSubscriptionSubscription>) => void,
) => {
  useSubscription<
    ClientDeletedSubscriptionSubscription,
    ClientDeletedSubscriptionSubscriptionVariables
  >(CLIENT_DELETED, {
    onData,
  });
};
