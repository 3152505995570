import { useSubscription } from '@apollo/client';
import {
  FinancialTilesUpdatedSubscription,
  FinancialTilesUpdatedSubscriptionVariables,
} from './__generated__/useOnFinancialTilesUpdated.gql';
import { ON_FINANCIAL_TILES_UPDATED } from './useOnFinancialTilesUpdated.gql';

export default function useOnFinancialTilesUpdated(onData?: () => void) {
  useSubscription<
    FinancialTilesUpdatedSubscription,
    FinancialTilesUpdatedSubscriptionVariables
  >(ON_FINANCIAL_TILES_UPDATED, {
    onData,
  });
}
