import { useQuery, QueryHookOptions } from '@apollo/client';
import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';

import {
  FetchClientListQuery,
  FetchClientListQueryVariables,
} from './__generated__/useClientListQuery.gql';
import { FETCH_CLIENT_LIST } from './useClientListQuery.gql';
import { useClientCreatedSubscription } from '../useClientCreatedSubscription';
import { OrderTypes } from '__generated__/globalTypes';

type ClientListFilterType = {
  search?: string;
  clientStatus?: string;
};

type ClientListSortingType = {
  [key: string]: OrderTypes;
};

export const useClientListQuery = (
  options?: QueryHookOptions<
    FetchClientListQuery,
    FetchClientListQueryVariables
  > & {
    filter?: ClientListFilterType;
    sorting?: ClientListSortingType;
  },
) => {
  const { filter, sorting, ...queryOptions } = options || {};
  const query = useQuery<FetchClientListQuery, FetchClientListQueryVariables>(
    FETCH_CLIENT_LIST,
    queryOptions,
  );

  useClientCreatedSubscription({
    onData: () => {
      query.refetch();
    },
  });

  const filteredAndSortedData = useMemo(() => {
    const data = query.data?.clients.items || [];

    const filteredData = data.filter((client) => {
      const clientSearchStr = `${client.name} ${client.email}`.toLowerCase();

      return (
        clientSearchStr.includes(filter?.search?.toLowerCase() || '') &&
        (!filter?.clientStatus || client.status === filter.clientStatus)
      );
    });

    const [key, orderType] =
      Object.entries(sorting || {}).find(([key, orderType]) => !!orderType) ||
      [];

    const sortedData = key ? sortBy(filteredData, key) : filteredData;

    return orderType === OrderTypes.ASC ? sortedData : sortedData.reverse();
  }, [query.data, filter, sorting]);

  return {
    ...query,
    data: { clients: { items: filteredAndSortedData } },
  };
};
