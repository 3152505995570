import { useQuery, QueryHookOptions } from '@apollo/client';

import { FetchCalendarsQuery } from './__generated__/useCalendars.gql';
import { FETCH_CALENDARS } from './useCalendars.gql';
import { useClientDeletedSubscription } from 'hooks/chat/useClientDeletedSubscription';

export const useCalendars = (options?: QueryHookOptions) => {
  const query = useQuery<FetchCalendarsQuery>(FETCH_CALENDARS, options);

  useClientDeletedSubscription(({ data: { data } }) => {
    if (data?.clientDeleted.id) {
      query.refetch();
    }
  });

  return {
    ...query,
    data: query.data && {
      ...query.data,
      calendars: query.data.calendars.map((c) => {
        return {
          ...c,
          // TODO: remove when appclose makes the colors consistent
          color: c.color.length < 7 ? `#${c.color}` : c.color,
        };
      }),
    },
  };
};
