import { useMemo } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { traceError } from '@appclose/core';

import { EntityIdsFieldName, Queries } from './useListFiles.const';
import {
  ListFilesQuery,
  ListFilesQueryVariables,
  UseListFilesPropsType,
  UseListFilesType,
} from './useListFiles.types';
import { ON_FILE_CREATED, ON_FILE_DELETED } from './useListFiles.gql';
import {
  OnFileCreatedSubscription,
  OnFileCreatedSubscriptionVariables,
  OnFileDeletedSubscription,
  OnFileDeletedSubscriptionVariables,
} from './__generated__/useListFiles.gql';

export default function useListFiles({
  entityType,
  input,
  skip,
  useSubscriptions = true,
  context,
}: UseListFilesPropsType): UseListFilesType {
  const { loading, data, refetch } = useQuery<
    ListFilesQuery,
    ListFilesQueryVariables
  >(Queries[entityType], {
    variables: input,
    skip,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    context,
  });
  const files = useMemo(() => data?.files, [data?.files]);

  useSubscription<
    OnFileCreatedSubscription,
    OnFileCreatedSubscriptionVariables
  >(ON_FILE_CREATED, {
    variables: {
      input: {
        [EntityIdsFieldName[entityType]]: input?.filter?.entityIds,
        from: input?.filter?.from,
        to: input?.filter?.to,
      },
    },
    skip: !useSubscriptions,
    context,
    async onData() {
      try {
        await refetch({
          skip: 0,
          take: (files?.items || []).length + 1,
        });
      } catch (e) {
        traceError(e as Error);
      }
    },
  });

  useSubscription<
    OnFileDeletedSubscription,
    OnFileDeletedSubscriptionVariables
  >(ON_FILE_DELETED, {
    variables: {
      input: {
        includeFilesIds: files?.items.map(({ id }) => id),
      },
    },
    skip: !useSubscriptions || !files || !files.items.length,
    context,
    async onData() {
      try {
        await refetch({
          skip: 0,
          take: files?.items.length || undefined,
        });
      } catch (e) {
        traceError(e as Error);
      }
    },
  });

  return {
    filesLoading: loading,
    files,
  };
}
