import { useCallback, useMemo } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { permissionProvider, usePopupModal } from '@appclose/core';

import { BankAccountClasses } from '__generated__/globalTypes';

import { BANK_ACCOUNT_MODAL } from 'constants/modals';
import { PermissionActions, PermissionResources } from 'constants/permissions';

import { openModal } from 'controllers/modal';

import ConnectFeeBankAccountModal from 'components/modals/popups/ConnectFeeBankAccountModal';

import {
  BankAccountCreatedSubscription,
  BankAccountCreatedSubscriptionVariables,
  BankAccountDeletedSubscription,
  BankAccountDeletedSubscriptionVariables,
  BankAccountUpdatedSubscription,
  BankAccountUpdatedSubscriptionVariables,
  FetchBankAccountsQuery,
  FetchBankAccountsQueryVariables,
} from './__generated__/useBankAccounts.gql';
import {
  FETCH_BANK_ACCOUNTS,
  ON_BANK_ACCOUNT_CREATED,
  ON_BANK_ACCOUNT_DELETED,
  ON_BANK_ACCOUNT_UPDATED,
} from './useBankAccounts.gql';
import { BankAccountsType } from './useBankAccounts.types';

export default function useBankAccounts(): BankAccountsType {
  const hasListPermissions = permissionProvider().hasPermission(
    PermissionResources.BANK_ACCOUNT,
    PermissionActions.LIST,
  );
  const hasCreatePermissions = permissionProvider().hasPermission(
    PermissionResources.BANK_ACCOUNT,
    PermissionActions.CREATE,
  );

  const { loading, data, refetch } = useQuery<
    FetchBankAccountsQuery,
    FetchBankAccountsQueryVariables
  >(FETCH_BANK_ACCOUNTS, {
    skip: !hasListPermissions,
  });

  useSubscription<
    BankAccountUpdatedSubscription,
    BankAccountUpdatedSubscriptionVariables
  >(ON_BANK_ACCOUNT_UPDATED, {
    onData: () => refetch(),
    skip: !hasListPermissions,
  });

  useSubscription<
    BankAccountCreatedSubscription,
    BankAccountCreatedSubscriptionVariables
  >(ON_BANK_ACCOUNT_CREATED, {
    onData: () => refetch(),
    skip: !hasListPermissions,
  });

  useSubscription<
    BankAccountDeletedSubscription,
    BankAccountDeletedSubscriptionVariables
  >(ON_BANK_ACCOUNT_DELETED, {
    onData: () => refetch(),
    skip: !hasListPermissions,
  });

  const bankAccounts = data?.bankAccounts;

  const operatingAccount = useMemo(
    () =>
      bankAccounts?.find((acc) => acc.class === BankAccountClasses.OPERATING),
    [bankAccounts],
  );
  const trustAccount = useMemo(
    () => bankAccounts?.find((acc) => acc.class === BankAccountClasses.TRUST),
    [bankAccounts],
  );

  const { openPopupModal: openFeeAccountConnectPopupModal } = usePopupModal(
    ConnectFeeBankAccountModal,
    refetch,
  );

  const onAccountConnect = useCallback((accountClass: BankAccountClasses) => {
    openModal(BANK_ACCOUNT_MODAL, { accountClass });
  }, []);

  const onTrustAccountConnect = useCallback(
    () => onAccountConnect(BankAccountClasses.TRUST),
    [onAccountConnect],
  );
  const onOperatingAccountConnect = useCallback(
    () => onAccountConnect(BankAccountClasses.OPERATING),
    [onAccountConnect],
  );
  const onFeeAccountConnect = useCallback(() => {
    openFeeAccountConnectPopupModal();
  }, [openFeeAccountConnectPopupModal]);

  return {
    loading,
    bankAccounts,
    operatingAccount,
    trustAccount,
    refetchBankAccounts: refetch,
    onTrustAccountConnect: hasCreatePermissions
      ? onTrustAccountConnect
      : undefined,
    onOperatingAccountConnect: hasCreatePermissions
      ? onOperatingAccountConnect
      : undefined,
    onFeeAccountConnect,
  };
}
